import gql from 'graphql-tag'


const detailResponse = `
  id type name
  qty unitRatio price
  discount discountPrice totalPrice
  inventoryId inventory {id type code name}
  unitId unit {id name}
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {
    id docId
  }
}`


export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  order: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_ITEM = (templateType) => gql`mutation CREATE_ORDER_ITEM ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderItemInput!) {
  order: createDoc${templateType}OrderItem (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_SERVICE = (templateType) => gql`mutation CREATE_ORDER_SERVICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderServiceInput!) {
  order: createDoc${templateType}OrderService (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_EXPENSE = (templateType) => gql`mutation CREATE_ORDER_EXPENSE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderExpenseInput!) {
  order: createDoc${templateType}OrderExpense (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_DISCOUNT_PRICE = (templateType) => gql`mutation CREATE_ORDER_DISCOUNT_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderDiscountPriceInput!) {
  order: createDoc${templateType}OrderDiscountPrice (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_VAT_PRICE = (templateType) => gql`mutation CREATE_ORDER_VAT_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderVatPriceInput!) {
  order: createDoc${templateType}OrderVatPrice (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const CREATE_ORDER_NET_PRICE = (templateType) => gql`mutation CREATE_ORDER_NET_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderNetPriceInput!) {
  order: createDoc${templateType}OrderNetPrice (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`


export const UPDATE_ORDER = (templateType) => gql`mutation UPDATE_ORDER ($docType: String!, $orderId: Int!, $qty: Float, $totalPrice: Float) {
  order: updateDoc${templateType}Order (docType: $docType, orderId: $orderId, qty: $qty, totalPrice: $totalPrice) {
    id docId parentId
  }
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  order: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id docId}
}`


export const LIST_ORDER_CONFIG = (templateType) => gql`query LIST_ORDER_CONFIG ($docType: String!, $contactId: Int!) {
  configs: listDoc${templateType}OrderConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault
    options
  }
}`

export const DETAIL_ORDER_RECENT = (templateType) => gql`query DETAIL_ORDER_RECENT ($docType: String!, $docId: Int!, $inventoryId: Int!) {
  item: detailDoc${templateType}OrderRecent (docType: $docType, docId: $docId, inventoryId: $inventoryId) {
    id type name unitId
    qty price discount
  }
}`

export const LIST_ORDER_ITEM = (templateType) => gql`query LIST_ORDER_ITEM ($docType: String!, $contactId: Int!, $q: FilterInput) {
  items: listDoc${templateType}OrderItem (docType: $docType, contactId: $contactId, q: $q) {
    id type name isDefault inventoryId
    inventory {
      id type code name
      unitId unit {id name}
      properties {
        id name targetValue
        unitId unit {id name}
      }
    }
    children {
      id type salesCredit isDefault
    }
  }
}`

export const LIST_ORDER_SERVICE = (templateType) => gql`query LIST_ORDER_SERVICE ($docType: String!, $contactId: Int!, $q: FilterInput) {
  items: listDoc${templateType}OrderService (docType: $docType, contactId: $contactId, q: $q) {
    id type name isDefault inventoryId
    inventory {
      id type code name
    }
    children {
      id type salesCredit isDefault
    }
  }
}`
